<template>
  <div class="oferta">
    <div class="oferta-container">
      <b-row>
        <b-col>
          <h1>
            {{ $t("oferta") }}
          </h1>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <div
            v-if="lang == 'ru'"
            class="oferta-card"
            style="padding-left:20px;padding-right:20px"
          >
            <p>
              Мазкур ҳужжат Ўзбекистон Республикаси Президенти ҳузуридаги
              Тадбиркорлик субъектларининг ҳуқуқлари ва қонуний манфаатларини
              ҳимоя қилиш бўйича вакилининг “ЯГОНА ДАВЛАТ НАЗОРАТИ” ахборот
              тизимидан (кейинги ўринларда - Тизим) тадбиркорларнинг рўйхатдан
              ўтиши, шахсий кабинет очиши ва ундан фойдаланиш бўйича Ўзбекистон
              Республикаси Фуқаролик кодексининг 367-моддаси ва 369-моддаси
              иккинчи қисмига мувофиқ хизмат кўрсатиш шартномасини (кейинги
              ўринларда – Шартнома) тузиш юзасидан лаёқатли бўлган
              тадбиркорларга (кейинги ўринларда – Фойдаланувчи) расмий таклиф
              ҳисобланади. <br />
              Фойдаланувчи томонидан мазкур офертада белгиланган ҳаракатларнинг
              бажарилиши, у томонидан қуйидаги шартлар сўзсиз қабул
              қилинганлигини англатади (Офертанинг акцепти). Офертани акцепт
              қилган пайтдан шартнома тузилган ҳисобланади. Мазкур шартномада
              тўғридан-тўғри назарда тутилмаган барча ҳолларда, томонлар
              Ўзбекистон Республикаси қонун ҳужжатлари ва иш муомаласи
              одатларига риоя қиладилар.
            </p>
            <p class="text-center mb-0 pb-0" style=" font-weight: bold;">
              1. АТАМА ВА ТАЪРИФЛАР.
            </p>
            <p class="mt-0 pt-0">
              1.1. Мазкур шартномада қуйидаги атама ва таърифлар қўлланилади:
              <br /><span style=" font-weight: bold;"
                >Шахсга доир маълумотларни қайта ишлаш</span
              >
              – шахсга доир маълумотларни йиғиш, тизимлаштириш, сақлаш,
              ўзгартириш, тўлдириш, улардан фойдаланиш, уларни бериш, тарқатиш,
              узатиш, эгасизлантириш ва йўқ қилиш бўйича битта ёки бир нечта
              ҳаракатларни амалга ошириш; <br /><span
                style=" font-weight: bold;"
                >Шахсга доир маълумотлар</span
              >
              – Тизимдан фойдаланиш учун фойдаланувчи томонидан тақдим этилган
              муайян жисмоний шахсга тааллуқли бўлган ёки уни идентификация
              қилиш имконини берадиган ахборот; <br /><span
                style=" font-weight: bold;"
              >
                Хизмат кўрсатувчи Хизмат кўрсатувчи</span
              >- фойдаланувчига
              <span style=" font-weight: bold;"> “govcontrol.uz”</span>манзили
              орқали тадбиркорлик субъектлари фаолияти бўйича ваколатли органлар
              томонидан ўтказиладиган текширишлар тўғрисидаги ҳамда уларнинг
              ҳуқуқ ва манфаатларини ҳимоя қилиш билан боғлиқ маълумотларни
              жойлаштириб борувчи ташкилот; <br /><span
                style=" font-weight: bold;"
                >Фойдаланувчи</span
              >
              – мазкур оферта шартларини қабул қилган ва
              <span style=" font-weight: bold;"> “govcontrol.uz”</span>сайти
              орақали хизматларни олувчи лаёқатли жисмоний шахс.
            </p>
            <p class="text-center mb-0 pb-0" style=" font-weight: bold;">
              2. АКЦЕПТ ТАРТИБИ
            </p>
            <p class="mt-0 pt-0">
              2.1. Мазкур Офертанинг акцепти
              <span style=" font-weight: bold;"> “govcontrol.uz”</span>сайти
              орқали авторизациядан ўтиб, хизмат кўратувчи томонидан
              кўрсатиладиган хизматларни олиш орқали амалга оширилади. <br />
              2.2. Мазкур Шартномани тузиш орқали фойдаланувчи хизмат
              курсатувчига амалдаги шахсга доир маълумотлар тўғрисидаги қонун
              ҳужжатлари талабларига мувофиқ, ушбу шартномани бажариш мақсадида,
              автоматизация воситаларидан фойдаланган ёки улардан фойдаланмаган
              ҳолда, ўзининг шахсга доир маълумотларига (шу жумладан, мобиль
              телефон рақами, Ф.И.Ш., рўйхатдан ўтиш билан боғлиқ маълумотлар,
              шунингдек, бошқа шахсга доир маълумотлар) ишлов беришга муддатсиз
              розилигини беради. <br />Шунингдек, ушбу орқали фойдаланувчи
              мазкур шартнома бўйича хизмат кўрсатувчига тақдим этилган шахсга
              доир маълумотларни ушбу шартномани бажариш мақсадида учинчи
              шахсларга тақдим этишга розилик беради. <br />2.3. Хизмат
              кўрсатувчи Фойдаланувчининг шахсга доир маълумотларнинг махфийлиги
              бўйича Ўзбекистон Республикасининг амалдаги қонун ҳужжатлари
              талабларига мувофиқ барча зарур бўлган чораларни кўришини
              Фойдаланувчига кафолатлайди. Фойдаланувчи унинг шахсга доир
              маълумотларига ишлов беришга розилиги (шу жумладан учинчи
              шахсларга ҳам) муддатсиз ҳисобланиб, истаган пайтда шахсга доир
              маълумотларига ишлов беришга розилигини қайтариб олиши мумкин.
              <br />2.4. Тизимдан фойдаланишдан аввал Фойдаланувчи мазкур
              Офертанинг матни билан тўлиқ танишиб чиқиши зарур. Фойдаланувчи
              Офертанинг шартларидан тўлиқ ёки қисман норози бўлса, Тизимдан
              фойдаланишдан бош тортиши зарур.
            </p>
            <p class="text-center mb-0 pb-0" style=" font-weight: bold;">
              3. ШАРТНОМА ПРЕДМЕТИ
            </p>
            <p class="mt-0 pt-0">
              3.1. Фойдаланувчи хизмат кўрсатувчининг
              <span style=" font-weight: bold;"> “govcontrol.uz”</span>манзилида
              руйхатдан ўтади. Руйхатдан ўтиш жараёнида шахсий маълумотлар,
              тадбиркорлик субъектнинг юридик манзили ва бошқа реквизитлари
              тўлдирилади. Фойдаланувчи Рўйхатдан ўтиш ва тизимдан фойдаланиш
              пайтида берилган маълумотларнинг аниқ, тўғри ва долзарб эканлигига
              ва ушбу маълумотлардан фойдаланиш ҳуқуқига эга эканлигига кафолат
              беради. Фойдаланувчи маълумотлари ўзгарса, уларни янгилаш
              мажбуриятини олади. <br />3.2. Рўйхатдан ўтгандан кейин
              тадбиркорга шахсий кабинет очилади. Шахсий кабинетда ўз фаолиятида
              рўйхатга олинган текширишлар тўғрисида маълумотлар ҳамда давлат
              назорат функциялари, мажбурий талаблар ва текшириш ҳуқуқи эга
              бўлган мансабдор шахслар реестрлари мавжуд бўлади. Фойдаланувчи
              тизим орқали ушбу маълумотлар ва реестрлардан фойдаланиши ҳамда
              тизимдан рўйхатдан ўтиш ва фойдаланиш жараёнида тизим орқали смс
              хабарномалар олиши мумкин. <br />Шунингдек, шахсий кабинет орқали
              Фойдаланувчи ўз фаолиятида ўтказилаётган текширувларни
              Текширувларни рўйхатга олиш китобида рўйхатга олиши, текширув
              жараёни юзасидан ариза ва шикоят қолдириши, ўз фикрларини йўллай
              олиши мумкин. <br />Шунингдек тизимда видео-дарслар ва қўлланмалар
              жойлаштирилади. Тизим орқали унга жойлаштирилган ресурслардан
              фойдаланиш бепул. <br />Тизимдан фойдаланиш жаранида
              Фойдаланувчига йўлланадиган смс хабарномалар учун Фойдаланувчининг
              мобил алоқа ҳисоб рақамидан мобил алоқа операторлари ва
              контент-провайдерлар томонидан белгиланган нархларга асосан
              қўшимча тўловлар ечиб олиниши мумкин.
            </p>
            <p class="text-center mb-0 pb-0" style=" font-weight: bold;">
              4. ТАРАФЛАР МАЖБУРИЯТЛАРИ
            </p>
            <p class="mt-0 pt-0">
              4.1. Хизмат кўрсатувчи қуйидагиларга мажбур: <br />
              <span style=" font-weight: bold;"> “govcontrol.uz”</span>
              тизимининг узлуксиз ишини таъминлаш (учинчи шахсларнинг айби билан
              юзага келадиган узилиш ҳолатлари бундан мустасно), шахсга доир
              маълумотларнинг махфийлигига риоя қилиш, уларга ноқонуний ишлов
              беришнинг олдини олиш, уларнинг яхлитлиги ва сақланишини
              таъминлайдиган ҳуқуқий, ташкилий ва техник чораларни кўриш.
              <br />4.2. Фойдаланувчи қуйидагиларга мажбур: <br />мазкур
              офертанинг талабларини бажариш, Тизимга уланувчи воситанинг
              ахборот хавфсизлигини мустақил равишда таъминлаш; ўзининг
              лаёқатлиги, шунингдек Тизимдан фирибгарлик ва бошқа ғайриқонуний
              мақсадларда фойдаланмаслигини тасдиқлаш ва кафолатлаш, Хизмат
              кўрсатувчининг розилигини олдиндан олмасдан шартнома бўйича ҳуқуқ
              ва мажбуриятларини учинчи шахсларга бермаслик. <br />4.3. Хизмат
              кўрсатувчи бир томонлама мазкур Офертага ўзгартириш киритишга
              ҳақли, бунда Хизмат кўрсатувчи Фойдаланувчини бу ҳақда Тизим ёки
              СМС-хабар орқали огоҳлантиради.
            </p>
            <p class="text-center mb-0 pb-0" style=" font-weight: bold;">
              5. ТОМОНЛАРНИНГ ЖАВОБГАРЛИГИ
            </p>
            <p class="mt-0 pt-0">
              5.1. Томонлар мазкур шартноманинг талабларини бажармаганлиги ёки
              лозим даражада бажармаганлиги учун Ўзбекистон Республикаси
              қонунчилигига мувофиқ жавоб берадилар. <br />
              5.2. Фойдаланувчи мазкур шартнома бўйича ўз мажбуриятларини
              бажармаганлиги ёки лозим даражада бажармаганлиги натижасида
              вужудга келган ҳар қандай зарар ёки салбий оқибатларга ўзи жавоб
              беради. <br />
              5.3. Фойдаланувчи хавфсиз бўлмаган уланишлардан фойдаланган ҳолда,
              ёхуд зарарли дастур ва иловалар таҳдиди остида ўзининг шахсга доир
              маълумотларини қурилмага киритишида уларнинг хавфсизлигига тўлиқ
              жавоб беради. <br />
              5.4. Фойдаланувчи томонидан шартнома шартлари бажарилмаганлиги ёки
              лозим даражада бажарилмаганлиги оқибатида Хизмат кўрсатувчи
              шартномада белгиланган мажбуриятларни бажара олмаганлиги учун
              Хизмат кўрсатувчи жавобгар бўлмайди.
              <br />5.5. Фойдаланувчи шартнома шартлари билан ўз вақтида ёки
              буткул танишмаганлиги оқибатида вужудга келадиган зарар учун
              Хизмат кўрсатувчи жавобгар бўлмайди. <br />5.6. Хизмат
              кўрсатувчининг назорат доирасига кирмайдиган сабабларга кўра
              шартнома шартлари лозим даражада бажарилмаслигига Хизмат
              кўрсатувчи жавобгар бўлмайди. <br />Хизмат кўрсатувчининг назорат
              доираси фақат ушбу Тизим хизматларининг ишлаши билан чекланиб,
              учинчи шахсларнинг айби билан вужудга келган узилишларга ёхуд
              давлат органлари ёки суд томонидан қуйилган чеклов ва тақиқларни
              ўз ичига олмайди. <br />5.7. Ҳар бир Томон ушбу шартномада
              белгиланган жавобгарлик чекланганлигини тан олган ҳолда, бошқа
              Томон мазкур шартномани тузишни ва ушбу чекловлар тарафлар
              ўртасидаги келишувлар учун зарур асос эканлигини тан олади. Бунда,
              томонларнинг назоратидан ташқаридаги ҳар қандай шароитлар
              оқибатида, шу жумладан, ҳуқумат фаолияти ёки террористик
              ҳаракатлар, зилзилалар, ёнғинлар, тошқинлар ёки бошқа табиий
              офатлар, иш шароитлари билан боғлиқ вазиятлар, электр
              таъминотидаги узилишлар ва Интернетга уланишидаги бузилишлар, бу
              билан чегараланмаган ҳолда, шунга ўхшаш ҳолатларда томонларнинг
              ҳеч бири шартнома талабларини бажармаганлиги ёки бажаришни
              кечиктирганлиги учун жавоб бермайди.
            </p>
            <p class="text-center mb-0 pb-0" style=" font-weight: bold;">
              6. ШАРТНОМАНИ БЕКОР ҚИЛИШ
            </p>
            <p class="mt-0 pt-0">
              6.1. Фойдаланувчи Тизимдаги ўз
              <span style=" font-weight: bold;"
                >аккаунтини ўчириб, шахсий кабинетдан чиқиб кетган ҳолда</span
              >
              мазкур шартномани ҳар қандай вақтда бажармасликка ҳақли.
              <br />6.2. Мазкур шартнома аккаунт ўчирилган вақтдан бошлаб бекор
              қилинган деб ҳисобланади.
            </p>
            <p class="text-center mb-0 pb-0" style=" font-weight: bold;">
              7. КЕЛИШМОВЧИЛИКЛАРНИ КЎРИБ ЧИҚИШ ТАРТИБИ
            </p>
            <p class="mt-0 pt-0">
              7.1. Мазкур шартномадан келиб чиқувчи ҳар қандай низо, ўзаро
              музокаралар орқали, ёхуд Фойдаланувчининг ёзма мурожаати асосида
              шикоят тартибида кўриб чиқилиши зарур. Мурожаатни кўриб чиқиш ва
              ва унинг мазмунига қуйиладиган талаблар амалдаги Ўзбекистон
              Республикаси қонунчилиги билан белгиланади. <br />
              7.2. Агар вужудга келган низо музокаралар ёки шикоят тартибида ҳал
              этилмаса, низо Ўзбекистон Республикасининг амалдаги қонунчилиги
              билан белгиланган тартибда жавобгар жойлашган жойдаги
              тааллуқлилиги бўйича тегишли судда кўриб чиқилиши керак. <br />
              7.3. Фойдаланувчилар шикоятлари билан боғлиқ ишлар ва техник
              кўллаб-қувватлаш хизматлари Фойдаланувчиларга фақат сўровларни
              қабул қилиш, уларга ишлов бериш ва тегишли сўровларга жавобларни
              юбориш орқали амалга оширилади.
            </p>
            <p class="text-center mb-0 pb-0" style=" font-weight: bold;">
              8. БОШҚА ШАРТЛАР
            </p>
            <p class="mt-0 pt-0">
              8.1. Тўғридан-тўғри шартномада назарда тутилмаган ҳолатлар
              Ўзбекистон Республикасининг амалдаги қонунчилигида белгиланган
              тартибда амалга оширилади. <br />
              8.2. Ушбу таклифни қабул қилиб, Фойдаланувчи ушбу шартноманинг
              барча шартларини сўзсиз қабул қилганлигини тасдиқлайди ва уни
              бажаришни ўз зиммасига олади.
            </p>
          </div>
          <div
            v-if="lang == 'uz_cyrl'"
            class="oferta-card"
            style="padding-left:20px;padding-right:20px"
          >
            <p>
              Мазкур ҳужжат Ўзбекистон Республикаси Президенти ҳузуридаги
              Тадбиркорлик субъектларининг ҳуқуқлари ва қонуний манфаатларини
              ҳимоя қилиш бўйича вакилининг “ЯГОНА ДАВЛАТ НАЗОРАТИ” ахборот
              тизимидан (кейинги ўринларда - Тизим) тадбиркорларнинг рўйхатдан
              ўтиши, шахсий кабинет очиши ва ундан фойдаланиш бўйича Ўзбекистон
              Республикаси Фуқаролик кодексининг 367-моддаси ва 369-моддаси
              иккинчи қисмига мувофиқ хизмат кўрсатиш шартномасини (кейинги
              ўринларда – Шартнома) тузиш юзасидан лаёқатли бўлган
              тадбиркорларга (кейинги ўринларда – Фойдаланувчи) расмий таклиф
              ҳисобланади. <br />
              Фойдаланувчи томонидан мазкур офертада белгиланган ҳаракатларнинг
              бажарилиши, у томонидан қуйидаги шартлар сўзсиз қабул
              қилинганлигини англатади (Офертанинг акцепти). Офертани акцепт
              қилган пайтдан шартнома тузилган ҳисобланади. Мазкур шартномада
              тўғридан-тўғри назарда тутилмаган барча ҳолларда, томонлар
              Ўзбекистон Республикаси қонун ҳужжатлари ва иш муомаласи
              одатларига риоя қиладилар.
            </p>
            <p class="text-center mb-0 pb-0" style=" font-weight: bold;">
              1. АТАМА ВА ТАЪРИФЛАР.
            </p>
            <p class="mt-0 pt-0">
              1.1. Мазкур шартномада қуйидаги атама ва таърифлар қўлланилади:
              <br /><span style=" font-weight: bold;"
                >Шахсга доир маълумотларни қайта ишлаш</span
              >
              – шахсга доир маълумотларни йиғиш, тизимлаштириш, сақлаш,
              ўзгартириш, тўлдириш, улардан фойдаланиш, уларни бериш, тарқатиш,
              узатиш, эгасизлантириш ва йўқ қилиш бўйича битта ёки бир нечта
              ҳаракатларни амалга ошириш; <br /><span
                style=" font-weight: bold;"
                >Шахсга доир маълумотлар</span
              >
              – Тизимдан фойдаланиш учун фойдаланувчи томонидан тақдим этилган
              муайян жисмоний шахсга тааллуқли бўлган ёки уни идентификация
              қилиш имконини берадиган ахборот; <br /><span
                style=" font-weight: bold;"
              >
                Хизмат кўрсатувчи Хизмат кўрсатувчи</span
              >- фойдаланувчига
              <span style=" font-weight: bold;"> “govcontrol.uz”</span>манзили
              орқали тадбиркорлик субъектлари фаолияти бўйича ваколатли органлар
              томонидан ўтказиладиган текширишлар тўғрисидаги ҳамда уларнинг
              ҳуқуқ ва манфаатларини ҳимоя қилиш билан боғлиқ маълумотларни
              жойлаштириб борувчи ташкилот; <br /><span
                style=" font-weight: bold;"
                >Фойдаланувчи</span
              >
              – мазкур оферта шартларини қабул қилган ва
              <span style=" font-weight: bold;"> “govcontrol.uz”</span>сайти
              орақали хизматларни олувчи лаёқатли жисмоний шахс.
            </p>
            <p class="text-center mb-0 pb-0" style=" font-weight: bold;">
              2. АКЦЕПТ ТАРТИБИ
            </p>
            <p class="mt-0 pt-0">
              2.1. Мазкур Офертанинг акцепти
              <span style=" font-weight: bold;"> “govcontrol.uz”</span>сайти
              орқали авторизациядан ўтиб, хизмат кўратувчи томонидан
              кўрсатиладиган хизматларни олиш орқали амалга оширилади. <br />
              2.2. Мазкур Шартномани тузиш орқали фойдаланувчи хизмат
              курсатувчига амалдаги шахсга доир маълумотлар тўғрисидаги қонун
              ҳужжатлари талабларига мувофиқ, ушбу шартномани бажариш мақсадида,
              автоматизация воситаларидан фойдаланган ёки улардан фойдаланмаган
              ҳолда, ўзининг шахсга доир маълумотларига (шу жумладан, мобиль
              телефон рақами, Ф.И.Ш., рўйхатдан ўтиш билан боғлиқ маълумотлар,
              шунингдек, бошқа шахсга доир маълумотлар) ишлов беришга муддатсиз
              розилигини беради. <br />Шунингдек, ушбу орқали фойдаланувчи
              мазкур шартнома бўйича хизмат кўрсатувчига тақдим этилган шахсга
              доир маълумотларни ушбу шартномани бажариш мақсадида учинчи
              шахсларга тақдим этишга розилик беради. <br />2.3. Хизмат
              кўрсатувчи Фойдаланувчининг шахсга доир маълумотларнинг махфийлиги
              бўйича Ўзбекистон Республикасининг амалдаги қонун ҳужжатлари
              талабларига мувофиқ барча зарур бўлган чораларни кўришини
              Фойдаланувчига кафолатлайди. Фойдаланувчи унинг шахсга доир
              маълумотларига ишлов беришга розилиги (шу жумладан учинчи
              шахсларга ҳам) муддатсиз ҳисобланиб, истаган пайтда шахсга доир
              маълумотларига ишлов беришга розилигини қайтариб олиши мумкин.
              <br />2.4. Тизимдан фойдаланишдан аввал Фойдаланувчи мазкур
              Офертанинг матни билан тўлиқ танишиб чиқиши зарур. Фойдаланувчи
              Офертанинг шартларидан тўлиқ ёки қисман норози бўлса, Тизимдан
              фойдаланишдан бош тортиши зарур.
            </p>
            <p class="text-center mb-0 pb-0" style=" font-weight: bold;">
              3. ШАРТНОМА ПРЕДМЕТИ
            </p>
            <p class="mt-0 pt-0">
              3.1. Фойдаланувчи хизмат кўрсатувчининг
              <span style=" font-weight: bold;"> “govcontrol.uz”</span>манзилида
              руйхатдан ўтади. Руйхатдан ўтиш жараёнида шахсий маълумотлар,
              тадбиркорлик субъектнинг юридик манзили ва бошқа реквизитлари
              тўлдирилади. Фойдаланувчи Рўйхатдан ўтиш ва тизимдан фойдаланиш
              пайтида берилган маълумотларнинг аниқ, тўғри ва долзарб эканлигига
              ва ушбу маълумотлардан фойдаланиш ҳуқуқига эга эканлигига кафолат
              беради. Фойдаланувчи маълумотлари ўзгарса, уларни янгилаш
              мажбуриятини олади. <br />3.2. Рўйхатдан ўтгандан кейин
              тадбиркорга шахсий кабинет очилади. Шахсий кабинетда ўз фаолиятида
              рўйхатга олинган текширишлар тўғрисида маълумотлар ҳамда давлат
              назорат функциялари, мажбурий талаблар ва текшириш ҳуқуқи эга
              бўлган мансабдор шахслар реестрлари мавжуд бўлади. Фойдаланувчи
              тизим орқали ушбу маълумотлар ва реестрлардан фойдаланиши ҳамда
              тизимдан рўйхатдан ўтиш ва фойдаланиш жараёнида тизим орқали смс
              хабарномалар олиши мумкин. <br />Шунингдек, шахсий кабинет орқали
              Фойдаланувчи ўз фаолиятида ўтказилаётган текширувларни
              Текширувларни рўйхатга олиш китобида рўйхатга олиши, текширув
              жараёни юзасидан ариза ва шикоят қолдириши, ўз фикрларини йўллай
              олиши мумкин. <br />Шунингдек тизимда видео-дарслар ва қўлланмалар
              жойлаштирилади. Тизим орқали унга жойлаштирилган ресурслардан
              фойдаланиш бепул. <br />Тизимдан фойдаланиш жаранида
              Фойдаланувчига йўлланадиган смс хабарномалар учун Фойдаланувчининг
              мобил алоқа ҳисоб рақамидан мобил алоқа операторлари ва
              контент-провайдерлар томонидан белгиланган нархларга асосан
              қўшимча тўловлар ечиб олиниши мумкин.
            </p>
            <p class="text-center mb-0 pb-0" style=" font-weight: bold;">
              4. ТАРАФЛАР МАЖБУРИЯТЛАРИ
            </p>
            <p class="mt-0 pt-0">
              4.1. Хизмат кўрсатувчи қуйидагиларга мажбур: <br />
              <span style=" font-weight: bold;"> “govcontrol.uz”</span>
              тизимининг узлуксиз ишини таъминлаш (учинчи шахсларнинг айби билан
              юзага келадиган узилиш ҳолатлари бундан мустасно), шахсга доир
              маълумотларнинг махфийлигига риоя қилиш, уларга ноқонуний ишлов
              беришнинг олдини олиш, уларнинг яхлитлиги ва сақланишини
              таъминлайдиган ҳуқуқий, ташкилий ва техник чораларни кўриш.
              <br />4.2. Фойдаланувчи қуйидагиларга мажбур: <br />мазкур
              офертанинг талабларини бажариш, Тизимга уланувчи воситанинг
              ахборот хавфсизлигини мустақил равишда таъминлаш; ўзининг
              лаёқатлиги, шунингдек Тизимдан фирибгарлик ва бошқа ғайриқонуний
              мақсадларда фойдаланмаслигини тасдиқлаш ва кафолатлаш, Хизмат
              кўрсатувчининг розилигини олдиндан олмасдан шартнома бўйича ҳуқуқ
              ва мажбуриятларини учинчи шахсларга бермаслик. <br />4.3. Хизмат
              кўрсатувчи бир томонлама мазкур Офертага ўзгартириш киритишга
              ҳақли, бунда Хизмат кўрсатувчи Фойдаланувчини бу ҳақда Тизим ёки
              СМС-хабар орқали огоҳлантиради.
            </p>
            <p class="text-center mb-0 pb-0" style=" font-weight: bold;">
              5. ТОМОНЛАРНИНГ ЖАВОБГАРЛИГИ
            </p>
            <p class="mt-0 pt-0">
              5.1. Томонлар мазкур шартноманинг талабларини бажармаганлиги ёки
              лозим даражада бажармаганлиги учун Ўзбекистон Республикаси
              қонунчилигига мувофиқ жавоб берадилар. <br />
              5.2. Фойдаланувчи мазкур шартнома бўйича ўз мажбуриятларини
              бажармаганлиги ёки лозим даражада бажармаганлиги натижасида
              вужудга келган ҳар қандай зарар ёки салбий оқибатларга ўзи жавоб
              беради. <br />
              5.3. Фойдаланувчи хавфсиз бўлмаган уланишлардан фойдаланган ҳолда,
              ёхуд зарарли дастур ва иловалар таҳдиди остида ўзининг шахсга доир
              маълумотларини қурилмага киритишида уларнинг хавфсизлигига тўлиқ
              жавоб беради. <br />
              5.4. Фойдаланувчи томонидан шартнома шартлари бажарилмаганлиги ёки
              лозим даражада бажарилмаганлиги оқибатида Хизмат кўрсатувчи
              шартномада белгиланган мажбуриятларни бажара олмаганлиги учун
              Хизмат кўрсатувчи жавобгар бўлмайди.
              <br />5.5. Фойдаланувчи шартнома шартлари билан ўз вақтида ёки
              буткул танишмаганлиги оқибатида вужудга келадиган зарар учун
              Хизмат кўрсатувчи жавобгар бўлмайди. <br />5.6. Хизмат
              кўрсатувчининг назорат доирасига кирмайдиган сабабларга кўра
              шартнома шартлари лозим даражада бажарилмаслигига Хизмат
              кўрсатувчи жавобгар бўлмайди. <br />Хизмат кўрсатувчининг назорат
              доираси фақат ушбу Тизим хизматларининг ишлаши билан чекланиб,
              учинчи шахсларнинг айби билан вужудга келган узилишларга ёхуд
              давлат органлари ёки суд томонидан қуйилган чеклов ва тақиқларни
              ўз ичига олмайди. <br />5.7. Ҳар бир Томон ушбу шартномада
              белгиланган жавобгарлик чекланганлигини тан олган ҳолда, бошқа
              Томон мазкур шартномани тузишни ва ушбу чекловлар тарафлар
              ўртасидаги келишувлар учун зарур асос эканлигини тан олади. Бунда,
              томонларнинг назоратидан ташқаридаги ҳар қандай шароитлар
              оқибатида, шу жумладан, ҳуқумат фаолияти ёки террористик
              ҳаракатлар, зилзилалар, ёнғинлар, тошқинлар ёки бошқа табиий
              офатлар, иш шароитлари билан боғлиқ вазиятлар, электр
              таъминотидаги узилишлар ва Интернетга уланишидаги бузилишлар, бу
              билан чегараланмаган ҳолда, шунга ўхшаш ҳолатларда томонларнинг
              ҳеч бири шартнома талабларини бажармаганлиги ёки бажаришни
              кечиктирганлиги учун жавоб бермайди.
            </p>
            <p class="text-center mb-0 pb-0" style=" font-weight: bold;">
              6. ШАРТНОМАНИ БЕКОР ҚИЛИШ
            </p>
            <p class="mt-0 pt-0">
              6.1. Фойдаланувчи Тизимдаги ўз
              <span style=" font-weight: bold;"
                >аккаунтини ўчириб, шахсий кабинетдан чиқиб кетган ҳолда</span
              >
              мазкур шартномани ҳар қандай вақтда бажармасликка ҳақли.
              <br />6.2. Мазкур шартнома аккаунт ўчирилган вақтдан бошлаб бекор
              қилинган деб ҳисобланади.
            </p>
            <p class="text-center mb-0 pb-0" style=" font-weight: bold;">
              7. КЕЛИШМОВЧИЛИКЛАРНИ КЎРИБ ЧИҚИШ ТАРТИБИ
            </p>
            <p class="mt-0 pt-0">
              7.1. Мазкур шартномадан келиб чиқувчи ҳар қандай низо, ўзаро
              музокаралар орқали, ёхуд Фойдаланувчининг ёзма мурожаати асосида
              шикоят тартибида кўриб чиқилиши зарур. Мурожаатни кўриб чиқиш ва
              ва унинг мазмунига қуйиладиган талаблар амалдаги Ўзбекистон
              Республикаси қонунчилиги билан белгиланади. <br />
              7.2. Агар вужудга келган низо музокаралар ёки шикоят тартибида ҳал
              этилмаса, низо Ўзбекистон Республикасининг амалдаги қонунчилиги
              билан белгиланган тартибда жавобгар жойлашган жойдаги
              тааллуқлилиги бўйича тегишли судда кўриб чиқилиши керак. <br />
              7.3. Фойдаланувчилар шикоятлари билан боғлиқ ишлар ва техник
              кўллаб-қувватлаш хизматлари Фойдаланувчиларга фақат сўровларни
              қабул қилиш, уларга ишлов бериш ва тегишли сўровларга жавобларни
              юбориш орқали амалга оширилади.
            </p>
            <p class="text-center mb-0 pb-0" style=" font-weight: bold;">
              8. БОШҚА ШАРТЛАР
            </p>
            <p class="mt-0 pt-0">
              8.1. Тўғридан-тўғри шартномада назарда тутилмаган ҳолатлар
              Ўзбекистон Республикасининг амалдаги қонунчилигида белгиланган
              тартибда амалга оширилади. <br />
              8.2. Ушбу таклифни қабул қилиб, Фойдаланувчи ушбу шартноманинг
              барча шартларини сўзсиз қабул қилганлигини тасдиқлайди ва уни
              бажаришни ўз зиммасига олади.
            </p>
          </div>
          <div
            v-if="lang == 'uz_latn'"
            class="oferta-card"
            style="padding-left:20px;padding-right:20px"
          >
            <p>
              Мазкур ҳужжат Ўзбекистон Республикаси Президенти ҳузуридаги
              Тадбиркорлик субъектларининг ҳуқуқлари ва қонуний манфаатларини
              ҳимоя қилиш бўйича вакилининг “ЯГОНА ДАВЛАТ НАЗОРАТИ” ахборот
              тизимидан (кейинги ўринларда - Тизим) тадбиркорларнинг рўйхатдан
              ўтиши, шахсий кабинет очиши ва ундан фойдаланиш бўйича Ўзбекистон
              Республикаси Фуқаролик кодексининг 367-моддаси ва 369-моддаси
              иккинчи қисмига мувофиқ хизмат кўрсатиш шартномасини (кейинги
              ўринларда – Шартнома) тузиш юзасидан лаёқатли бўлган
              тадбиркорларга (кейинги ўринларда – Фойдаланувчи) расмий таклиф
              ҳисобланади. <br />
              Фойдаланувчи томонидан мазкур офертада белгиланган ҳаракатларнинг
              бажарилиши, у томонидан қуйидаги шартлар сўзсиз қабул
              қилинганлигини англатади (Офертанинг акцепти). Офертани акцепт
              қилган пайтдан шартнома тузилган ҳисобланади. Мазкур шартномада
              тўғридан-тўғри назарда тутилмаган барча ҳолларда, томонлар
              Ўзбекистон Республикаси қонун ҳужжатлари ва иш муомаласи
              одатларига риоя қиладилар.
            </p>
            <p class="text-center mb-0 pb-0" style=" font-weight: bold;">
              1. АТАМА ВА ТАЪРИФЛАР.
            </p>
            <p class="mt-0 pt-0">
              1.1. Мазкур шартномада қуйидаги атама ва таърифлар қўлланилади:
              <br /><span style=" font-weight: bold;"
                >Шахсга доир маълумотларни қайта ишлаш</span
              >
              – шахсга доир маълумотларни йиғиш, тизимлаштириш, сақлаш,
              ўзгартириш, тўлдириш, улардан фойдаланиш, уларни бериш, тарқатиш,
              узатиш, эгасизлантириш ва йўқ қилиш бўйича битта ёки бир нечта
              ҳаракатларни амалга ошириш; <br /><span
                style=" font-weight: bold;"
                >Шахсга доир маълумотлар</span
              >
              – Тизимдан фойдаланиш учун фойдаланувчи томонидан тақдим этилган
              муайян жисмоний шахсга тааллуқли бўлган ёки уни идентификация
              қилиш имконини берадиган ахборот; <br /><span
                style=" font-weight: bold;"
              >
                Хизмат кўрсатувчи Хизмат кўрсатувчи</span
              >- фойдаланувчига
              <span style=" font-weight: bold;"> “govcontrol.uz”</span>манзили
              орқали тадбиркорлик субъектлари фаолияти бўйича ваколатли органлар
              томонидан ўтказиладиган текширишлар тўғрисидаги ҳамда уларнинг
              ҳуқуқ ва манфаатларини ҳимоя қилиш билан боғлиқ маълумотларни
              жойлаштириб борувчи ташкилот; <br /><span
                style=" font-weight: bold;"
                >Фойдаланувчи</span
              >
              – мазкур оферта шартларини қабул қилган ва
              <span style=" font-weight: bold;"> “govcontrol.uz”</span>сайти
              орақали хизматларни олувчи лаёқатли жисмоний шахс.
            </p>
            <p class="text-center mb-0 pb-0" style=" font-weight: bold;">
              2. АКЦЕПТ ТАРТИБИ
            </p>
            <p class="mt-0 pt-0">
              2.1. Мазкур Офертанинг акцепти
              <span style=" font-weight: bold;"> “govcontrol.uz”</span>сайти
              орқали авторизациядан ўтиб, хизмат кўратувчи томонидан
              кўрсатиладиган хизматларни олиш орқали амалга оширилади. <br />
              2.2. Мазкур Шартномани тузиш орқали фойдаланувчи хизмат
              курсатувчига амалдаги шахсга доир маълумотлар тўғрисидаги қонун
              ҳужжатлари талабларига мувофиқ, ушбу шартномани бажариш мақсадида,
              автоматизация воситаларидан фойдаланган ёки улардан фойдаланмаган
              ҳолда, ўзининг шахсга доир маълумотларига (шу жумладан, мобиль
              телефон рақами, Ф.И.Ш., рўйхатдан ўтиш билан боғлиқ маълумотлар,
              шунингдек, бошқа шахсга доир маълумотлар) ишлов беришга муддатсиз
              розилигини беради. <br />Шунингдек, ушбу орқали фойдаланувчи
              мазкур шартнома бўйича хизмат кўрсатувчига тақдим этилган шахсга
              доир маълумотларни ушбу шартномани бажариш мақсадида учинчи
              шахсларга тақдим этишга розилик беради. <br />2.3. Хизмат
              кўрсатувчи Фойдаланувчининг шахсга доир маълумотларнинг махфийлиги
              бўйича Ўзбекистон Республикасининг амалдаги қонун ҳужжатлари
              талабларига мувофиқ барча зарур бўлган чораларни кўришини
              Фойдаланувчига кафолатлайди. Фойдаланувчи унинг шахсга доир
              маълумотларига ишлов беришга розилиги (шу жумладан учинчи
              шахсларга ҳам) муддатсиз ҳисобланиб, истаган пайтда шахсга доир
              маълумотларига ишлов беришга розилигини қайтариб олиши мумкин.
              <br />2.4. Тизимдан фойдаланишдан аввал Фойдаланувчи мазкур
              Офертанинг матни билан тўлиқ танишиб чиқиши зарур. Фойдаланувчи
              Офертанинг шартларидан тўлиқ ёки қисман норози бўлса, Тизимдан
              фойдаланишдан бош тортиши зарур.
            </p>
            <p class="text-center mb-0 pb-0" style=" font-weight: bold;">
              3. ШАРТНОМА ПРЕДМЕТИ
            </p>
            <p class="mt-0 pt-0">
              3.1. Фойдаланувчи хизмат кўрсатувчининг
              <span style=" font-weight: bold;"> “govcontrol.uz”</span>манзилида
              руйхатдан ўтади. Руйхатдан ўтиш жараёнида шахсий маълумотлар,
              тадбиркорлик субъектнинг юридик манзили ва бошқа реквизитлари
              тўлдирилади. Фойдаланувчи Рўйхатдан ўтиш ва тизимдан фойдаланиш
              пайтида берилган маълумотларнинг аниқ, тўғри ва долзарб эканлигига
              ва ушбу маълумотлардан фойдаланиш ҳуқуқига эга эканлигига кафолат
              беради. Фойдаланувчи маълумотлари ўзгарса, уларни янгилаш
              мажбуриятини олади. <br />3.2. Рўйхатдан ўтгандан кейин
              тадбиркорга шахсий кабинет очилади. Шахсий кабинетда ўз фаолиятида
              рўйхатга олинган текширишлар тўғрисида маълумотлар ҳамда давлат
              назорат функциялари, мажбурий талаблар ва текшириш ҳуқуқи эга
              бўлган мансабдор шахслар реестрлари мавжуд бўлади. Фойдаланувчи
              тизим орқали ушбу маълумотлар ва реестрлардан фойдаланиши ҳамда
              тизимдан рўйхатдан ўтиш ва фойдаланиш жараёнида тизим орқали смс
              хабарномалар олиши мумкин. <br />Шунингдек, шахсий кабинет орқали
              Фойдаланувчи ўз фаолиятида ўтказилаётган текширувларни
              Текширувларни рўйхатга олиш китобида рўйхатга олиши, текширув
              жараёни юзасидан ариза ва шикоят қолдириши, ўз фикрларини йўллай
              олиши мумкин. <br />Шунингдек тизимда видео-дарслар ва қўлланмалар
              жойлаштирилади. Тизим орқали унга жойлаштирилган ресурслардан
              фойдаланиш бепул. <br />Тизимдан фойдаланиш жаранида
              Фойдаланувчига йўлланадиган смс хабарномалар учун Фойдаланувчининг
              мобил алоқа ҳисоб рақамидан мобил алоқа операторлари ва
              контент-провайдерлар томонидан белгиланган нархларга асосан
              қўшимча тўловлар ечиб олиниши мумкин.
            </p>
            <p class="text-center mb-0 pb-0" style=" font-weight: bold;">
              4. ТАРАФЛАР МАЖБУРИЯТЛАРИ
            </p>
            <p class="mt-0 pt-0">
              4.1. Хизмат кўрсатувчи қуйидагиларга мажбур: <br />
              <span style=" font-weight: bold;"> “govcontrol.uz”</span>
              тизимининг узлуксиз ишини таъминлаш (учинчи шахсларнинг айби билан
              юзага келадиган узилиш ҳолатлари бундан мустасно), шахсга доир
              маълумотларнинг махфийлигига риоя қилиш, уларга ноқонуний ишлов
              беришнинг олдини олиш, уларнинг яхлитлиги ва сақланишини
              таъминлайдиган ҳуқуқий, ташкилий ва техник чораларни кўриш.
              <br />4.2. Фойдаланувчи қуйидагиларга мажбур: <br />мазкур
              офертанинг талабларини бажариш, Тизимга уланувчи воситанинг
              ахборот хавфсизлигини мустақил равишда таъминлаш; ўзининг
              лаёқатлиги, шунингдек Тизимдан фирибгарлик ва бошқа ғайриқонуний
              мақсадларда фойдаланмаслигини тасдиқлаш ва кафолатлаш, Хизмат
              кўрсатувчининг розилигини олдиндан олмасдан шартнома бўйича ҳуқуқ
              ва мажбуриятларини учинчи шахсларга бермаслик. <br />4.3. Хизмат
              кўрсатувчи бир томонлама мазкур Офертага ўзгартириш киритишга
              ҳақли, бунда Хизмат кўрсатувчи Фойдаланувчини бу ҳақда Тизим ёки
              СМС-хабар орқали огоҳлантиради.
            </p>
            <p class="text-center mb-0 pb-0" style=" font-weight: bold;">
              5. ТОМОНЛАРНИНГ ЖАВОБГАРЛИГИ
            </p>
            <p class="mt-0 pt-0">
              5.1. Томонлар мазкур шартноманинг талабларини бажармаганлиги ёки
              лозим даражада бажармаганлиги учун Ўзбекистон Республикаси
              қонунчилигига мувофиқ жавоб берадилар. <br />
              5.2. Фойдаланувчи мазкур шартнома бўйича ўз мажбуриятларини
              бажармаганлиги ёки лозим даражада бажармаганлиги натижасида
              вужудга келган ҳар қандай зарар ёки салбий оқибатларга ўзи жавоб
              беради. <br />
              5.3. Фойдаланувчи хавфсиз бўлмаган уланишлардан фойдаланган ҳолда,
              ёхуд зарарли дастур ва иловалар таҳдиди остида ўзининг шахсга доир
              маълумотларини қурилмага киритишида уларнинг хавфсизлигига тўлиқ
              жавоб беради. <br />
              5.4. Фойдаланувчи томонидан шартнома шартлари бажарилмаганлиги ёки
              лозим даражада бажарилмаганлиги оқибатида Хизмат кўрсатувчи
              шартномада белгиланган мажбуриятларни бажара олмаганлиги учун
              Хизмат кўрсатувчи жавобгар бўлмайди.
              <br />5.5. Фойдаланувчи шартнома шартлари билан ўз вақтида ёки
              буткул танишмаганлиги оқибатида вужудга келадиган зарар учун
              Хизмат кўрсатувчи жавобгар бўлмайди. <br />5.6. Хизмат
              кўрсатувчининг назорат доирасига кирмайдиган сабабларга кўра
              шартнома шартлари лозим даражада бажарилмаслигига Хизмат
              кўрсатувчи жавобгар бўлмайди. <br />Хизмат кўрсатувчининг назорат
              доираси фақат ушбу Тизим хизматларининг ишлаши билан чекланиб,
              учинчи шахсларнинг айби билан вужудга келган узилишларга ёхуд
              давлат органлари ёки суд томонидан қуйилган чеклов ва тақиқларни
              ўз ичига олмайди. <br />5.7. Ҳар бир Томон ушбу шартномада
              белгиланган жавобгарлик чекланганлигини тан олган ҳолда, бошқа
              Томон мазкур шартномани тузишни ва ушбу чекловлар тарафлар
              ўртасидаги келишувлар учун зарур асос эканлигини тан олади. Бунда,
              томонларнинг назоратидан ташқаридаги ҳар қандай шароитлар
              оқибатида, шу жумладан, ҳуқумат фаолияти ёки террористик
              ҳаракатлар, зилзилалар, ёнғинлар, тошқинлар ёки бошқа табиий
              офатлар, иш шароитлари билан боғлиқ вазиятлар, электр
              таъминотидаги узилишлар ва Интернетга уланишидаги бузилишлар, бу
              билан чегараланмаган ҳолда, шунга ўхшаш ҳолатларда томонларнинг
              ҳеч бири шартнома талабларини бажармаганлиги ёки бажаришни
              кечиктирганлиги учун жавоб бермайди.
            </p>
            <p class="text-center mb-0 pb-0" style=" font-weight: bold;">
              6. ШАРТНОМАНИ БЕКОР ҚИЛИШ
            </p>
            <p class="mt-0 pt-0">
              6.1. Фойдаланувчи Тизимдаги ўз
              <span style=" font-weight: bold;"
                >аккаунтини ўчириб, шахсий кабинетдан чиқиб кетган ҳолда</span
              >
              мазкур шартномани ҳар қандай вақтда бажармасликка ҳақли.
              <br />6.2. Мазкур шартнома аккаунт ўчирилган вақтдан бошлаб бекор
              қилинган деб ҳисобланади.
            </p>
            <p class="text-center mb-0 pb-0" style=" font-weight: bold;">
              7. КЕЛИШМОВЧИЛИКЛАРНИ КЎРИБ ЧИҚИШ ТАРТИБИ
            </p>
            <p class="mt-0 pt-0">
              7.1. Мазкур шартномадан келиб чиқувчи ҳар қандай низо, ўзаро
              музокаралар орқали, ёхуд Фойдаланувчининг ёзма мурожаати асосида
              шикоят тартибида кўриб чиқилиши зарур. Мурожаатни кўриб чиқиш ва
              ва унинг мазмунига қуйиладиган талаблар амалдаги Ўзбекистон
              Республикаси қонунчилиги билан белгиланади. <br />
              7.2. Агар вужудга келган низо музокаралар ёки шикоят тартибида ҳал
              этилмаса, низо Ўзбекистон Республикасининг амалдаги қонунчилиги
              билан белгиланган тартибда жавобгар жойлашган жойдаги
              тааллуқлилиги бўйича тегишли судда кўриб чиқилиши керак. <br />
              7.3. Фойдаланувчилар шикоятлари билан боғлиқ ишлар ва техник
              кўллаб-қувватлаш хизматлари Фойдаланувчиларга фақат сўровларни
              қабул қилиш, уларга ишлов бериш ва тегишли сўровларга жавобларни
              юбориш орқали амалга оширилади.
            </p>
            <p class="text-center mb-0 pb-0" style=" font-weight: bold;">
              8. БОШҚА ШАРТЛАР
            </p>
            <p class="mt-0 pt-0">
              8.1. Тўғридан-тўғри шартномада назарда тутилмаган ҳолатлар
              Ўзбекистон Республикасининг амалдаги қонунчилигида белгиланган
              тартибда амалга оширилади. <br />
              8.2. Ушбу таклифни қабул қилиб, Фойдаланувчи ушбу шартноманинг
              барча шартларини сўзсиз қабул қилганлигини тасдиқлайди ва уни
              бажаришни ўз зиммасига олади.
            </p>
          </div>
          <div class="soglasen">
            <custom-button @click.native="ReturnRegister">
              <span style="margin-left:48px;margin-right:48px">
                {{ $t("agree") }}
              </span>
            </custom-button>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import customButton from "../components/elements/customButton.vue";
export default {
  components: { BRow, BCol, customButton },
  data() {
    return {
      lang: localStorage.getItem("locale") || "uz_cyrl",
    };
  },
  methods: {
    ReturnRegister() {
      this.$router.push({ name: "Register" });
    },
  },
};
</script>

<style></style>
